

import Joi from "joi";

const schema = Joi.object().keys({
    username: Joi.string().regex(/^[a-zA-Z0-9_]*$/).min(3).max(13).required(),
    password: Joi.string().min(6).required(),
  });

  export default {
    data() {
      return {
        id : '',
        status : '',
        id_kecamatan: '',
  
        form : {
          id : '',
          nama : '',
          email : '',
          username : '',
          password : '',
          nik: '',
          jenis_kelamin: '',
          alamat: '',
          tempat_lahir: '',
          tanggal_lahir: '',
          no_telp: '',
          file : null,
          file_old : null,
          status:'',
          kecamatan:'',
          des_kel_id:''
        },
        confirmPassword: "",
        createAt:"",
  
        file_path: this.$store.state.url.URL_APP + "uploads/",
       
        options: [
              'Laki-laki','Perempuan'
        ],
        list_data : [],
  
        page_first: 1,
        page_last: 0,
        no:0,
        cari_value: "",
        cek_load_data : true,
        desa: [],
        kecamatan:[],
        
        url: {
            KECAMATAN: this.$store.state.url.URL_APP + "api/v1/petadasarnew/"
          },
  
  
        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        mdl_akun : false,
        btn_add: false,

        signingUp : false,
        errorMessage:''
      }
    },
    methods: {
  
  
      getView : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_Pengguna + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                id : this.id,
                status : this.status,
                id_kecamatan: this.id_kecamatan

            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data[0].data1;
                this.page_last = res_data.lengthy;
                this.$store.commit("hideLoading");
                // console.log(res_data);
        });
      },
  
  
      addData : function() {
        this.errorMessage = "";

        if (this.validUser()) {
            // Jika user sdh valid lakukan pengiriman data ke server
            const body = {
              username: this.form.username,
              password: this.form.password
            };
            this.signingUp = true;

            fetch(this.$store.state.url.URL_Pengguna + 'add', {
                method: "POST",
                body: JSON.stringify(this.form),
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                }
            }).then((response) => {
                this.signingUp = false;
                if (response.ok) {
                    this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
                    this.getView();
                    // this.$router.push('/login');
                    // return response.json();
                }
                else{
                  return response.json().then(error => {
                    throw new Error(error.message);
                  });

                }
            })
            .catch((error) => {
              setTimeout(() => {
                this.signingUp = false;
                this.errorMessage = error.message;
              }, 1000);
            });
        }else{
          console.log("tidak valid");

        }

      },
  
  
      editDataAkun : function(){
        this.errorMessage = "";

        if (this.validUser()) {
            // Jika user sdh valid lakukan pengiriman data ke server
            const body = {
              username: this.form.username,
              password: this.form.password
            };
            this.signingUp = true;

            fetch(this.$store.state.url.URL_Pengguna + 'edit', {
                method: "POST",
                body: JSON.stringify(
                  
                {
                  id : this.form.id,
                  nama : this.form.nama,
                  email : this.form.email,
                  username : this.form.username,
                  no_telp : this.form.no_telp,
                  status : this.form.status,
                  password : this.form.password,
                  kecamatan : this.form.kecamatan,
                  des_kel_id : this.form.des_kel_id,
                  createAt : this.createAt
                }
                
                ),
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                }
            }).then((response) => {
                this.signingUp = false;
                if (response.ok) {
                    this.Notify('Sukses Merubah Data Akun', 'positive', 'check_circle_outline');
                    this.getView();
                    // this.$router.push('/login');
                    // return response.json();
                }
                else{
                  return response.json().then(error => {
                    throw new Error(error.message);
                  });

                }
            })
            .catch((error) => {
              setTimeout(() => {
                this.signingUp = false;
                this.errorMessage = error.message;
              }, 1000);
            });
        }else{
          console.log("tidak valid");

        }
      },

      editData : function(){
        var formData = new FormData();

        formData.append("id", this.form.id);
        formData.append("nik", this.form.nik);
        formData.append("alamat", this.form.alamat);
        formData.append("jenis_kelamin", this.form.jenis_kelamin);
        formData.append("tempat_lahir", this.form.tempat_lahir);
        formData.append("tanggal_lahir", this.form.tanggal_lahir);
        
        formData.append("file", this.form.file);
        formData.append("file_old", this.file_old);
        
        fetch(this.$store.state.url.URL_Pengguna + "editData", {
            method: "POST",
            headers: {
              
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },
  
      removeData : function(E){
        fetch(this.$store.state.url.URL_Pengguna + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({id : this.form.id})
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
  
      },
      selectData : function(data){
          this.form.id = data.id;
          this.form.nama = data.nama;
          this.form.email = data.email;
          this.form.username = data.username;
          this.form.no_telp = data.no_telp;
          this.form.status = data.status;
          this.form.nik = data.nik;
          this.form.jenis_kelamin = data.jenis_kelamin;
          this.form.alamat = data.alamat;
          this.form.tempat_lahir = data.tempat_lahir;
          this.form.tanggal_lahir = data.tanggal_lahir;
          
          
          this.form.kecamatan = data.kecamatan;
          this.form.des_kel_id = data.des_kel_id;

         
          
          this.form.file = data.file;
          this.form.file = data.file;
          this.createAt = data.createAt;


      },
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 1000,
            })
          },
          NotifyPass : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 5000,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },
  
  
      // ====================================== PAGINATE ====================================
  
      validUser : function(){
            if (this.form.password !== this.confirmPassword) {
                this.errorMessage = "Password dan Confirm Password harus sama. !";
                this.NotifyPass('Password dan Confirm Password harus sama. !', 'negative', 'check_circle_outline');
                return false;
            }
            // Memulai proses validasi melalui skema Joi yang sebelumnya dibuat
            // req.body merupakan data yang di kirim oleh client dan schema merupakan skema joi yg di buat sebelumnya
            const body = {
                    username: this.form.username,
                    password: this.form.password
            };


            const result = schema.validate(body);

            console.log(result.error)

            if (result.error == null || result.error ==undefined) {
                return true;
            }

            if (result.error.message.includes("username")) {
                // jika pesan error yang dihasilkan mengandung char "username" maka

                    this.errorMessage = "Username tidak valid (Min : 6 dan Max : 14 Karakter)";
                    this.NotifyPass('Username tidak valid (Min : 6 dan Max : 14 Karakter)', 'negative', 'check_circle_outline');
                  } else {
                    this.errorMessage = "Password tidak valid (Min : 6 Karakter)";
                    this.NotifyPass('Password tidak valid (Min : 6 Karakter)', 'negative', 'check_circle_outline');
                    //   console.log(result.error);

                }
            return false;
        },

        
        getKecamatan: function() {
                this.cek_load_data = true;
                fetch(this.url.KECAMATAN + "kecamatan", {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                  },
                  body: JSON.stringify({
                    kecamatan_id : this.id_kecamatan
                    
                  })
                })
                  .then(res => res.json())
                  .then(res_data => {
                      
                    var tampung=[];

                    var kode = '';

                      for(var i=0;i<res_data.length;i++){

                        if(res_data[i].hasil.kode<10){
                            kode = `0${res_data[i].hasil.kode}`;
                        }
                        else{
                            kode = `${res_data[i].hasil.kode}`
                        }


                        tampung.push({
                          // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.kode}`, 
                          kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.${kode}`, 
                          nama_kecamatan: res_data[i].hasil.uraian
                        })
                      }

                    
                      this.kecamatan = tampung;
                      
                      
                      this.cek_load_data = false;
                  });
            },

            getDesa: function() {
                this.cek_load_data = true;

              

                fetch(this.url.KECAMATAN + "desa", {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                  },
                  body: JSON.stringify({
                  kecamatan_id : this.form.kecamatan.id,
              })

                })
                  .then(res => res.json())
                  .then(res_data => {


                      var tampung=[];

                      var no_kec = '';


                        for(var i=0;i<res_data.length;i++){

                          if(res_data[i].no_kec<10){
                              no_kec = `0${res_data[i].no_kec}`;
                          }
                          else{
                              no_kec = `${res_data[i].no_kec}`
                          }


                          tampung.push({
                            // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.no_kec}`, 
                            des_kel_id: `${res_data[i].no_prop}.0${res_data[i].no_kab}.${no_kec}.${res_data[i].kode}`, 
                            nama_des_kel: res_data[i].uraian
                          })
                        }

                        // Tambah Ranowila
                              if(this.form.kecamatan.id == '74.05.18'){
                                tampung.push({
                                  // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.no_kec}`, 
                                  des_kel_id: '74.05.18.2006', 
                                  nama_des_kel: 'RANOWILA'
                                })
                              }
                              if(this.form.kecamatan.id == '74.05.07'){
                              
                                  // Remove Ranowila
                                    tampung.splice(16, 1);
                                
                              }



                        this.desa = tampung;
                        
                        this.cek_load_data = false;

                  });
            },

  
  
  
  
  
    },
  
    mounted () {
      var get_profile = JSON.parse(localStorage.profile);
      
      this.id = get_profile.id;
      this.status = get_profile.profile.status;
      this.id_kecamatan = get_profile.profile.id_kecamatan;

     
      this.getKecamatan(); 
      this.getView();
    },
  }
  